import React from 'react'
import { PageProps } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import LegalView from '../views/legal.view.js'

const LegalPage: React.FC<PageProps> = () => {
  const { t } = useTranslation('custom')
  return (
    <Layout seo={{ webflow: '5fea4622987096ae61ba639e', title: t('Mentions légals') }}>
      <LegalView />
    </Layout>
  )
}

export default LegalPage
